import axios from 'axios'

import {
  apiWrapper,
  buildConnectApiUrl,
  requestConfig,
} from '@lib/api/requests'

export const createSsoConnection = async (
  type: CustodianSsoConnection['type'],
): Promise<CustodianSsoConnection> => {
  const { data } = await apiWrapper(
    axios.post<{ ssoConnection: CustodianSsoConnection }>(
      buildConnectApiUrl(`/app/custodian/sso/${type.toLowerCase()}`),
      null,
      requestConfig,
    ),
  )

  return data.ssoConnection
}

export const configureSsoConnection = async (
  connectionId: string,
  type: CustodianSsoConnection['type'],
  data: ConfigureSamlConnectionRequest | ConfigureOidcConnectionRequest,
) => {
  console.log('configureSsoConnection', connectionId, type, data)
  await apiWrapper(
    axios.put<CustodianSsoConnection>(
      buildConnectApiUrl(
        `/app/custodian/sso/${type.toLowerCase()}/${connectionId}`,
      ),
      data,
      requestConfig,
    ),
  )
}

export default {
  createSsoConnection,
  configureSsoConnection,
}
