export const SettingsMenuItems = [
  {
    id: 'profile',
    title: 'Personal',
  },
  {
    id: 'organization',
    title: 'Organization',
  },
  {
    id: 'team',
    title: 'Team',
  },
  {
    id: 'custodian-api-keys',
    title: 'Custodian API Keys',
  },
  {
    id: 'client-api-keys',
    title: 'Test Client API Keys',
  },
  {
    id: 'webhook',
    title: 'Webhooks',
  },
  {
    id: 'wc-metadata',
    title: 'WalletConnect',
  },
  {
    id: 'security',
    title: 'Security',
  },
  {
    id: 'single-sign-on',
    title: 'Single Sign-On',
  },
  {
    id: 'aa',
    title: 'Account Abstraction',
  },
]
